<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">{{ pageTitle }}</h1>
        <!--end::Title-->
        <!--end::Title-->
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <div class="m-0">
          <router-link :to="{ name: 'advertisers.show', params: { id: advertiser.id } }"
            class="btn btn-sm btn-primary" v-if="advertiser">
            Voltar
          </router-link>
        </div>
        <!--end::Filter menu-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>

    <!-- CONTENT -->
    <!--begin::Products-->
    <div class="card card-flush">
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <form @submit.prevent="" class="text-left default-form">
          <LoaderOverlay
            size="big"
            color="color2"
            v-if="overlayLoader"
          />
          <div class="d-flex align-items-center gap-5">
            <div class="form-group mt-8 mb-6 col-12 col-md-5">
              <label class="form-label fw-bold">Nacionalidade</label>
              <select class="form-select" v-model.trim="form.country">
                <option disabled selected value="">Selecione</option>
                <option v-for="(country, index) in parsedCountries" :key="index" :value="country.code"
                  :selected="country.code === form.country">{{ country.name }}</option>
              </select>
            </div>
            <div class="form-group mb-3" v-if="!isEditPage">
              <label class="form-label fw-bold">Empresa vinculada*</label><br/>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model.trim="form.type" id="registration-type-advertiser" value="ADVERTISER">
                <label class="form-check-label" for="registration-type-advertiser">Próprio anunciante</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" v-model.trim="form.type" id="registration-type-agency" value="AGENCY">
                <label class="form-check-label" for="registration-type-agency">Agência</label>
              </div>
            </div>
          </div>
          <h4>Anunciante</h4>
          <div class="d-flex flex-column flex-md-row gap-5">
            <div class="form-group mb-3 col-12 col-md-3" v-if="form.country === 'BR'">
              <label class="form-label fw-bold">
                Perfil de cobrança*
              </label>
              <select class="form-select" v-model.trim="form.billing_profile">
                <option value="ADVERTISER">Anunciante</option>
                <option value="COMPANY">Empresa</option>
              </select>
            </div>
            <div class="form-group mb-3 col-12 col-md-3">
              <label class="form-label fw-bold">
                Moeda*
              </label>
              <select class="form-select" v-model.trim="form.currency" :disabled="advertiser">
                <option value="BRL">Real</option>
                <option value="EUR">Euro</option>
                <option value="USD">Dolar</option>
              </select>
            </div>
            <div class="form-group mb-3" v-if="form.country === 'BR'">
              <label class="form-label fw-bold col-12 col-md-5">CNPJ/CPF*</label>
              <the-mask class="form-control" placeholder="CNPJ/CPF" :mask="['###.###.###-##', '##.###.###/####-##']"
                v-model.trim="form.advertiser_document" />
            </div>
            <div class="form-group mb-3" v-else>
              <label class="form-label fw-bold col-12 col-md-5">Tax-ID*</label>
              <input type="text" class="form-control" placeholder="Tax-ID" v-model.trim="form.advertiser_document" />
            </div>
          </div>
          <div class="d-flex flex-column flex-md-row gap-5">
            <div class="form-group mb-3 col-12 col-md-5">
              <label class="form-label fw-bold">Nome</label>
              <input type="text" class="form-control" placeholder="Nome" v-model.trim="form.name" />
            </div>
          </div>
          <div class="d-flex flex-column flex-md-row gap-5">
            <div class="form-group mb-3 col-12 col-md-5">
              <label class="form-label fw-bold">Site*</label>
              <div class="input-group mb-3">
                <span class="input-group-text">https://</span>
                <input type="text" class="form-control" placeholder="empresa.com.br" v-model.trim="form.website" />
              </div>
            </div>
            <div class="form-group mb-3 col-12 col-md-5">
              <label class="form-label fw-bold">Categoria*</label>
              <div v-if="categoriesLoader">
                <Loader :size="'small'" :align="'start'" />
              </div>
              <div v-else>
                <select class="form-select" v-model.trim="form.category_id">
                  <option disabled="true" selected="true">Selecione</option>
                  <template v-if="categories.length > 0">
                    <option v-for="(category, index) in categories" :key="index" :value="category.id">{{ category.name
                      }}</option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div class="mb-5" v-if="form.country === 'BR'">
            <div class="position-relative">
              <LoaderOverlay size="big" v-if="zipcodeLoader" />
              <div class="d-lg-flex mb-3">
                <div class="col-lg-4 pl-0">
                  <div class="form-group">
                    <label class="form-label fw-bold">CEP*</label>
                    <TheMask :mask="'#####-###'" v-model.trim="form.address.zipcode" placeholder="" class="form-control"
                      @change.native="getZipcode('advertiser')" />
                  </div>
                </div>
                <div class="col-lg-8 px-0 ps-lg-3">
                  <div class="form-group">
                    <label class="form-label fw-bold">Rua*</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.street" placeholder=""
                      :readonly="readonlyForm" />
                  </div>
                </div>
              </div>
              <div class="d-lg-flex mb-3">
                <div class="col-lg-4 pl-0">
                  <div class="form-group">
                    <label class="form-label fw-bold">Número*</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.number" placeholder="" />
                  </div>
                </div>
                <div class="col-lg-4 pl-0 px-lg-3">
                  <div class="form-group">
                    <label class="form-label fw-bold">Complemento</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.complement"
                      placeholder="" />
                  </div>
                </div>
                <div class="col-lg-4 px-0">
                  <div class="form-group">
                    <label class="form-label fw-bold">Bairro*</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.neighborhood" placeholder=""
                      :readonly="readonlyForm" />
                  </div>
                </div>
              </div>
              <div class="d-lg-flex">
                <div class="col-lg-5 pl-0 pe-lg-3">
                  <div class="form-group">
                    <label class="form-label fw-bold">Cidade*</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.city" placeholder=""
                      readonly />
                  </div>
                </div>
                <div class="col-lg-5 px-0 pe-lg-3">
                  <div class="form-group">
                    <label class="form-label fw-bold">Estado*</label>
                    <input type="text" class="form-control" v-model.trim.trim="form.address.state" placeholder=""
                      readonly />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template v-if="!isEditPage">
            <h4>Usuário administrador</h4>
            <small>A senha padrão inicial para usuários criados no painel administrativo é <strong>mudar@123</strong>.<br> Recomendamos que o usuário altere essa senha na página "Minha Conta" da plataforma, utilizando o botão "Trocar Senha".</small>
            <div class="d-flex flex-column flex-md-row gap-5 my-3">
              <div class="form-group mb-3 col-12 col-md-4">
                <label class="form-label fw-bold">Nome</label>
                <input type="text" class="form-control" placeholder="Nome" v-model.trim="form.user_name" />
              </div>
              <div class="form-group mb-3 col-12 col-md-4">
                <label class="form-label fw-bold">E-mail</label>
                <input type="text" class="form-control" placeholder="E-mail" v-model.trim="form.user_email" />
              </div>
              <div class="form-group mb-3 col-12 col-md-3">
                <label class="form-label fw-bold">Telefone</label>
                <PhoneInput v-model.trim="form.user_phone" :country="form.country" />
              </div>
            </div>
          </template>
          <template v-if="form.type == 'AGENCY'">
            <h4>Empresa</h4>
            <div class="d-flex flex-column flex-md-row gap-5">
              <div class="form-group mb-3 col-12 col-md-5">
                <label class="form-label fw-bold">Razão Social* </label>
                <input type="text" class="form-control" placeholder="Nome" v-model.trim="form.company_name" />
              </div>
              <div class="form-group mb-3 col-12 col-md-5">
                <label class="form-label fw-bold">Nome Fantasia* </label>
                <input type="text" class="form-control" placeholder="Nome" v-model.trim="form.company_fantasy_name" />
              </div>
            </div>
            <div class="form-group mb-5" v-if="form.country === 'BR'">
              <label class="form-label fw-bold col-12 col-md-5">CNPJ/CPF*</label>
              <the-mask class="form-control" placeholder="CNPJ/CPF" :mask="['###.###.###-##', '##.###.###/####-##']"
                v-model.trim="form.company_document" />
            </div>
            <div class="form-group mb-5" v-else>
              <label class="form-label fw-bold col-12 col-md-5">Tax-ID*</label>
              <input type="text" class="form-control" placeholder="Tax-ID" v-model.trim="form.company_document" />
            </div>
            <div class="mb-5" v-if="form.country === 'BR'">
              <div class="position-relative">
                <LoaderOverlay size="big" v-if="zipcodeLoader" />
                <div class="d-lg-flex mb-3">
                  <div class="col-lg-4 pl-0">
                    <div class="form-group">
                      <label class="form-label fw-bold">CEP*</label>
                      <TheMask :mask="'#####-###'" v-model.trim="form.company_address.zipcode" placeholder="" class="form-control"
                        @change.native="getZipcode('company')" />
                    </div>
                  </div>
                  <div class="col-lg-8 px-0 ps-lg-3">
                    <div class="form-group">
                      <label class="form-label fw-bold">Rua*</label>
                      <input type="text" class="form-control" v-model.trim.trim="form.company_address.street" placeholder=""
                        :readonly="readonlyForm" />
                    </div>
                  </div>
                </div>
                <div class="d-lg-flex mb-3">
                  <div class="col-lg-4 pl-0">
                    <div class="form-group">
                      <label class="form-label fw-bold">Número*</label>
                      <input type="text" class="form-control" v-model.trim.trim="form.company_address.number" placeholder="" />
                    </div>
                  </div>
                  <div class="col-lg-4 pl-0 px-lg-3">
                    <div class="form-group">
                      <label class="form-label fw-bold">Complemento</label>
                      <input type="text" class="form-control" v-model.trim.trim="form.company_address.complement"
                        placeholder="" />
                    </div>
                  </div>
                  <div class="col-lg-4 px-0">
                    <div class="form-group">
                      <label class="form-label fw-bold">Bairro*</label>
                      <input type="text" class="form-control" v-model.trim.trim="form.company_address.neighborhood" placeholder=""
                        :readonly="readonlyForm" />
                    </div>
                  </div>
                </div>
                <div class="d-lg-flex">
                  <div class="col-lg-5 pl-0 pe-lg-3">
                    <div class="form-group">
                      <label class="form-label fw-bold">Cidade*</label>
                      <input type="text" class="form-control" v-model.trim.trim="form.company_address.city" placeholder=""
                        readonly />
                    </div>
                  </div>
                  <div class="col-lg-5 px-0 pe-lg-3">
                    <div class="form-group">
                      <label class="form-label fw-bold">Estado*</label>
                      <input type="text" class="form-control" v-model.trim.trim="form.company_address.state" placeholder=""
                        readonly />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div class="text-center mt-8">
            <Button @click="submit" class="btn btn-primary" :loading="loader">
              {{ buttonText }}
            </Button>
          </div>
        </form>
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Products-->
    <!-- END CONTENT -->
  </MainLayout>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import CategoriesService from '@/modules/categories/services/categories-service'
import { getZipcodeData, parseErrorResponseToArray } from '@/utils'
import variables from '@/mixins/variables'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'
import PhoneInput from '@/components/common/Input/PhoneInput'
import { TheMask } from 'vue-the-mask'
import MainLayout from '@/components/layouts/MainLayout'

export default {
  name: 'AdvertiserEdit',
  mixins: [variables],
  components: {
    Button,
    Loader,
    LoaderOverlay,
    MainLayout,
    PhoneInput,
    TheMask
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  data () {
    return {
      advertiser: null,
      companies: [],
      categoriesLoader: false,
      categories: [],
      form: {
        type: '',
        advertiser_document: '',
        billing_profile: 'ADVERTISER',
        currency: 'BRL',
        website: '',
        category_id: '',
        country: 'BR',
        address: {
          zipcode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        },
        user_name: '',
        user_email: '',
        user_phone: '',
        company_name: '',
        company_fantasy_name: '',
        company_document: '',
        company_address: {
          zipcode: '',
          street: '',
          number: '',
          complement: '',
          neighborhood: '',
          city: '',
          state: ''
        }
      },
      loader: false,
      overlayLoader: false,
      readonlyForm: true,
      selectedOption: '',
      zipcodeLoader: false,
      companiesLoader: false
    }
  },
  created () {
    this.getCategories()
    if (this.isEditPage) {
      this.getAdvertiser()
    }
  },
  computed: {
    buttonText () {
      return this.advertiser ? 'Salvar' : 'Criar anunciante'
    },
    companiesOptions () {
      const processedIds = new Set()
      return this.companies
        .filter(company => !processedIds.has(company.id) && processedIds.add(company.id))
        .map(company => ({ label: `${company.name}`, code: company.id }))
    },
    /**
     * Check if is edit page
     */
    isEditPage () {
      return this.$route.name === 'advertisers.edit'
    },
    /**
     * Get page title
     */
    pageTitle () {
      return this.$route.name === 'advertisers.create' ? 'Criar anunciante' : 'Editar anunciante'
    },
    /**
     * parse countries list
     */
    parsedCountries () {
      const countries = this.countries.map(item => {
        return {
          code: item.code,
          name: item.name
        }
      })

      countries.sort((a, b) => a.name.localeCompare(b.name))

      return countries
    }
  },
  methods: {
    /**
     * Get advertiser
     */
    async getAdvertiser () {
      this.overlayLoader = true
      try {
        const advertiser = await AdvertisersService.getAdvertiser(this.$route.params.id)
        this.advertiser = advertiser
        this.setAdvertiser(advertiser)
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.overlayLoader = false
    },
    /**
     * Get categories
     */
    async getCategories () {
      this.categoriesLoader = true
      this.content = null
      try {
        const categories = await CategoriesService.getCategories({ type: 'ADVERTISER' })
        this.categories = categories.data
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.categoriesLoader = false
    },
    /**
     * Get zipcode data given a zipcode
     */
    async getZipcode (address) {
      this.zipcodeLoader = true
      this.content = null
      try {
        if (address === 'advertiser') {
          const address = await getZipcodeData(this.form.address.zipcode)
          this.form.address.street = address.street
          this.form.address.neighborhood = address.neighborhood
          this.form.address.city = address.city
          this.form.address.state = address.state
          this.readonlyForm = !!address.street
        } if (address === 'company') {
          const companyAddress = await getZipcodeData(this.form.company_address.zipcode)
          this.form.company_address.street = companyAddress.street
          this.form.company_address.neighborhood = companyAddress.neighborhood
          this.form.company_address.city = companyAddress.city
          this.form.company_address.state = companyAddress.state
          this.readonlyForm = !!companyAddress.street
        }
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.zipcodeLoader = false
    },
    /**
     * Set advertiser data to form
     */
    setAdvertiser () {
      this.form.country = this.advertiser.country
      this.form.name = this.advertiser.name
      this.form.website = this.advertiser.website.replace('https://', '')
      this.form.advertiser_document = this.advertiser.document
      this.form.billing_profile = this.advertiser.billing_profile
      this.form.currency = this.advertiser.currency
      this.form.category_id = this.advertiser.category.id
      this.form.address.zipcode = this.advertiser.address.zipcode
      this.form.address.street = this.advertiser.address.street
      this.form.address.number = this.advertiser.address.number
      this.form.address.complement = this.advertiser.address.complement
      this.form.address.neighborhood = this.advertiser.address.neighborhood
      this.form.address.city = this.advertiser.address.city
      this.form.address.state = this.advertiser.address.state

      // Get categories
      if (this.categories.length === 0) this.getCategories()
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      try {
        let advertiser = null
        const form = Object.assign({}, this.form)
        if (form.website) form.website = `https://${form.website.replace('https://', '').replace('http://', '')}`
        if (!this.advertiser) advertiser = await AdvertisersService.createAdvertiser(form)
        else advertiser = await AdvertisersService.updateAdvertiser(this.advertiser.id, form)

        this.$router.push({ name: 'advertisers.show', params: { id: advertiser.id } })
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    }
  }
}
</script>
